import React from 'react';
import styles from './MissionAndVision.module.css';

const MissionAndVission = () => {
    return (
        <>
            <div className={styles.missionSection}>
                <div>
                <div className="flex flex-col items-center justify-center text-center  w-[75%] mx-auto">
    <h1 className="text-[32px] sm:text-[48px] font-bold text-[#ffffff] pt-20">
        Mission And Vision:
    </h1>
    <p className="text-[16px] sm:text-[22px] text-[#ffffff] mt-3 mb-6">
        Our mission is to be the best coaching center in Kalyan, providing
        top-tier education
        <br />
        that prepares students for academic and competitive success.
        <br />
        We envision a future where every student in Kalyan has access to
        <br />
        quality education that drives them to achieve their dreams.
    </p>
</div>

                <div className={styles.videoSection}>
                <iframe
                  className={styles.missionVideo}
                  src="https://www.youtube.com/embed/uPCfN_kD-vU?si=0rBX6X0Gg3b0M_fO"
                  title="YouTube video"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
                </div>
                </div>
            </div>
        </>
    )
}

export default MissionAndVission;